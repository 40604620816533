<template>
  <div>
    <ExtendedView
      :tabs="tabs"
      :selected-object="selectedPrinter"
      @clearSelectedObject="selectedPrinter = null"
    >
      <template v-slot:list="{ item }">
        <ActionList
          :key="item.key"
          :filters="[]"
          :load-items-callback="getPrinters"
          @actionSuccess="getPrinters"
          @actionFailure="getPrinters"
          empty-icon="mdi-credit-card-outline"
          :empty-text="$t('noPaymentsFound')"
          v-on="$listeners"
          :call-to-actions="callToActions"
        >
          <template v-slot:item="{ item, index }">
            <PrinterListItem
              :printer="item"
              :key="item.uuid"
              @selectPrinter="selectPrinter"
            />
            <v-divider
              inset
              :key="index"
            />
          </template>
        </ActionList>
      </template>

      <template slot="profile">
        <PrinterProfile
          v-if="selectedPrinter"
          :printer="selectedPrinter"
        />
      </template>
    </ExtendedView>
  </div>
</template>

<script>
  export default {
    name: 'Printers',
    components: {
      'PrinterListItem': () => import('@/components/printers/PrinterListItem'),
      'PrinterProfile': () => import('@/components/printers/PrinterProfile'),
      'ExtendedView': () => import('@/components/common/ExtendedView'),
			'ActionList': () => import('@/components/common/list/ActionList')
    },
    data() { 
      return {
        sort: 'desc',
        selectedPrinter: null,
        loading: false,
        callToActions: [
          {
            title: this.$t('addPrinter'), 
            callback: this.showPrinterDialog
          }
        ]
      }
    },
    methods: {
      selectPrinter(printer) {
        this.selectedPrinter = printer
      },
      getPrinters({limit, skip, conditions}) {
        return this.$store.dispatch('getPrinters', {limit: limit, conditions})
      }, 
      showPrinterDialog(){
        //Add printer
        this.$store.commit('updateDataToMutate', {
          title: this.$t('createPrinter'),
          objectToMutate: {}, 
          formComponent: 'components/printers/PrinterForm',
          saveCallback: function(newPrinter) {
            this.$store.dispatch('createPrinter', newPrinter).then(result => {
              if(result) {
                this.$store.commit('updateActionSuccess', {
                  message: this.$t('success'), 
                  subMessage: this.$t('successfullyCreatedPrinter'),  
                })
              } else {
                this.$store.commit('updateActionError', {
                  message: this.$t('error'), 
                  subMessage: this.$t('couldNotCreatePrinter')
                })
              }
            }).catch(() => {
              this.$store.commit('updateActionError', {
                message: this.$t('error'), 
                subMessage: this.$t('couldNotCreatePrinter')
              })
            })
          }
        })
      }
    },
    computed: {
      tabs() {
        return [
          {
            title: this.$t('all'),
            key: 'all'
          }
        ]
      },
      selectedAppBarTab() {
        return this.$store.state.selectedAppBarTab
      },
      actionSuccess() {
        return this.$store.state.actionSuccess
      }
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
	  },
    created() {
      this.$store.commit('updateAppBarExport', {
        color: 'pink',
        callback: this.exportPayments
      })
    }
  }
</script>
